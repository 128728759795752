import {
	AnalysisDataAggregation,
	AnalysisGridRow,
	AnalysisBalanceProperty,
	AnalysisTransactionValue,
	AnalysisBalanceValue,
	AnalysisType,
	AnalysisDisplaySettings,
	AnalysisDataRoot,
	mapGroupTypeToTQLField,
	groupByOptions,
	accountGroupByOptions,
	transactionGroupByOptions,
	AnalysisOtherGroupValue,
	sortAnalsyisAggregations,
	summaryColumnOptions,
	AnalysisTotalGroupValue,
	summaryOptionToMetricValue,
} from './analysis.model';
import { CollectionView } from '@grapecity/wijmo';
import { formatNumber } from '@angular/common';
import {
	GroupData,
	GroupPropData,
	Group,
	ScrollableDataViewModel,
	CellStyle,
	ScrollableSummaryColumn,
	ScrollableDataCustomStyle,
} from '../../scrollable-data-table/models/scrollable-data-table.model';
import { Currency, CurrencyDict } from 'src/app/shared/models/currency.model';
import { Formatter } from 'src/app/shared/utils/formatter';
import { CellRangeEventArgs, FlexGrid, ICellTemplateFunction } from '@grapecity/wijmo.grid';
import { Cadence } from './cadence.model';
import { BalanceAnalysisOption, BalanceAnalysisOptions } from './analysis-chart-view-model';
import { RoundingOption } from './report.model';
import { roundingDict } from '@trovata/app/shared/models/rounding.model';
import { TQLValuesDict } from '@trovata/app/shared/models/tql.model';
import { GenericOption } from '@trovata/app/shared/models/option.model';
import { DateTime } from 'luxon';

export class AnalysisDataViewModel implements ScrollableDataViewModel {
	view: CollectionView;

	balanceAnalysisOptions: BalanceAnalysisOptions = new BalanceAnalysisOptions();

	groupData: GroupData;
	dateArray: string[];
	dateLabels?: string[];
	summaryColumn?: ScrollableSummaryColumn;
	columnStyles: Record<string, CellStyle>;
	groupByTypeDisplayValues: string[] = [];
	displayProperties: string[];
	totalsDisplayProperties: string[];
	headerProperties: string[] = [];
	groupDisplayOrder: string[];
	trxnProperties: string[] = ['creditAmount', 'debitAmount', 'totalAmount'];
	frozenGridColumns: number;
	groupNameDict: { [groupId: string]: string } = {};
	balanceOptions: any[];
	secondaryBalanceProperty: AnalysisBalanceProperty;
	primaryBalanceProperty: AnalysisBalanceProperty;
	gridRows: AnalysisGridRow[] = [];
	placeholderValue: string;
	dialogData: number[] = [];
	isConvertedCurrency: boolean;
	showGridCurrencyColumn: boolean;
	extraCellWidth: number;

	private dateFormat: Intl.DateTimeFormatOptions;

	private trueRoundingOption: RoundingOption;

	private ungroupedKey: string;

	formatter: Formatter = new Formatter();
	wijmoTemplate: ICellTemplateFunction;
	wijmoCopyHandler: (grid: FlexGrid, copyingCells: CellRangeEventArgs) => void;

	constructor(
		public analysisData: AnalysisDataRoot<AnalysisTransactionValue> | AnalysisDataRoot<AnalysisBalanceValue>,
		private netOnly: boolean,
		private valuesDict: TQLValuesDict,
		private isSortable: boolean,
		public currencyConverted: Currency,
		private locale: string,
		private currencyDict: CurrencyDict,
		private displaySettings: AnalysisDisplaySettings
	) {
		// creating copy of analysisData so that sorting does not affect other components
		this.analysisData = JSON.parse(JSON.stringify(this.analysisData));
		this.columnStyles = {};
		this.ungroupedKey = 'ungrouped';
		this.isConvertedCurrency = this.analysisData.balanceProperty?.includes('Converted') || this.analysisData.type === AnalysisType.transactions;
		sortAnalsyisAggregations(this.analysisData.aggregation, displaySettings?.userOrdered ?? { order: [] }, true);
		this.setDateFormat();
		this.trueRoundingOption = this.displaySettings.trueRounding ? roundingDict.get(this.displaySettings.trueRoundingOption) : null;
		this.showGridCurrencyColumn = displaySettings.gridCurrencyColumn;
		this.summaryColumn = this.displaySettings.summaryColumn
			? {
					displayValue: summaryColumnOptions.find((option: GenericOption) => option.id === this.displaySettings.summaryColumn)?.displayValue,
					dataKey: this.displaySettings.summaryColumn,
				}
			: undefined;
		this.wijmoTemplate = this.formatter.currencyWijmoCellTemplate(this.currencyDict, null, this.trueRoundingOption, !displaySettings.gridCurrencySymbols);
		this.currencyConverted = this.currencyConverted;
		this.extraCellWidth = 0;
		this.loadAnalysisData();
	}

	private setDateFormat(): void {
		this.dateFormat = this.dateFormatter(this.analysisData.cadence);
	}

	private loadAnalysisData(): void {
		this.placeholderValue = this.formatter.formatValue(
			0,
			this.isConvertedCurrency || this.analysisData.type === AnalysisType.transactions ? this.currencyConverted : null,
			this.trueRoundingOption
		);
		if (this.analysisData.groupBy) {
			this.groupByTypeDisplayValues = this.analysisData.groupBy.map(groupBy => this.getGroupTypeDisplay(groupBy));
		}
		if (this.analysisData.type === AnalysisType.transactions) {
			this.generateTransactionGridRows();
		} else if (this.analysisData.type === AnalysisType.balances) {
			this.generateBalanceGridRows();
		}
	}

	generateTransactionGridRows(): void {
		if (this.analysisData.type === AnalysisType.transactions) {
			this.displayProperties = ['Credit', 'Debit', 'Net'];
			this.headerProperties.push('Net');

			this.groupData = new GroupData();
			this.groupData.sortable = this.isSortable;
			this.groupDisplayOrder = [];
			this.dateArray = [];
			this.dateLabels = [];
			this.gridRows = [];
			const transactionAnalysis: AnalysisDataRoot<AnalysisTransactionValue> = this.analysisData;
			if (!transactionAnalysis.aggregation?.length) {
				this.groupDisplayOrder.push(this.ungroupedKey);
				this.processTransactionGroup(transactionAnalysis, this.groupData, []);
			} else {
				transactionAnalysis.aggregation.forEach((group: AnalysisDataAggregation<AnalysisTransactionValue>) => {
					this.groupDisplayOrder.push(group.value);
					this.processTransactionGroup(group, this.groupData, []);
				});
			}
			this.sortGridRows(this.groupDisplayOrder, this.gridRows);

			// set totals rows
			const gridTotalsRow: AnalysisGridRow = new AnalysisGridRow('Total', []);
			gridTotalsRow.currency = this.currencyConverted.code;
			gridTotalsRow.currencyConverted = this.currencyConverted.code;
			this.groupData.totals = new Group('Total');
			this.groupData.totals.groupId = AnalysisTotalGroupValue;
			this.groupData.totals.displayProperties = this.displayProperties;
			if (transactionAnalysis.tagOverlap) {
				this.groupData.totals.displayProperties = ['Unique Total', 'Credit', 'Debit', 'Net'];
			}
			this.groupData.totals.headerProperties = this.headerProperties;
			this.groupData.totals.placeholderValue = this.placeholderValue;
			this.groupData.totals.displayProperties.forEach((property: string) => {
				this.groupData.totals.properties[property] = new GroupPropData(property, { clickableValues: true });
			});
			if (transactionAnalysis.tagOverlap) {
				this.buildTagOverlapSubproperties();
				if (transactionAnalysis.tagOverlap.tagOverlapAnalysis.affectedTags) {
					this.extraCellWidth = 24;
				}
			}
			this.dateArray.forEach((date: string, index: number) => {
				const netValue: number = (transactionAnalysis.summary[index].credit || 0) - (transactionAnalysis.summary[index].debit || 0);
				this.dialogData.push(netValue);
				gridTotalsRow[date] = netValue;
				this.setTotalsValues(date, netValue, transactionAnalysis, index);
			});
			if (this.summaryColumn) {
				const creditMetricsVal: number = summaryOptionToMetricValue(
					AnalysisType.transactions,
					transactionAnalysis.metrics,
					'credit',
					this.displaySettings.summaryColumn
				);
				const debitMetricsVal: number = summaryOptionToMetricValue(
					AnalysisType.transactions,
					transactionAnalysis.metrics,
					'debit',
					this.displaySettings.summaryColumn
				);
				const netMetricsVal: number = summaryOptionToMetricValue(
					AnalysisType.transactions,
					transactionAnalysis.metrics,
					'net',
					this.displaySettings.summaryColumn
				);
				this.groupData.totals.properties['Credit'].data[this.summaryColumn.dataKey] = this.formatter.formatValue(
					creditMetricsVal || 0,
					this.currencyConverted,
					this.trueRoundingOption
				);
				this.groupData.totals.properties['Debit'].data[this.summaryColumn.dataKey] = this.formatter.formatValue(
					-debitMetricsVal || 0,
					this.currencyConverted,
					this.trueRoundingOption
				);
				this.groupData.totals.properties['Net'].data[this.summaryColumn.dataKey] = this.formatter.formatValue(
					netMetricsVal,
					this.currencyConverted,
					this.trueRoundingOption
				);
				if (transactionAnalysis.tagOverlap) {
					const uniqueSummary: AnalysisDataRoot<AnalysisTransactionValue | AnalysisBalanceValue> = transactionAnalysis.tagOverlap.uniqueTransactionsAnalysis;
					const uniqueSummaryMetricsVal: number = summaryOptionToMetricValue(
						AnalysisType.transactions,
						uniqueSummary?.metrics,
						'net',
						this.displaySettings.summaryColumn
					);
					this.groupData.totals.properties['Unique Total'].data[this.summaryColumn.dataKey] = this.formatter.formatValue(
						uniqueSummaryMetricsVal || 0,
						this.currencyConverted,
						this.trueRoundingOption
					);
					this.groupData.totals.properties['Unique Total'].subProperty['Tag Delta'].data[this.summaryColumn.dataKey] = this.formatter.formatValue(
						uniqueSummary ? netMetricsVal - uniqueSummaryMetricsVal : 0,
						this.currencyConverted,
						this.trueRoundingOption
					);
				}
			}
			this.createCopyHandler();
			this.setFrozenColumns();
			if (this.groupDisplayOrder.length > 1 || this.groupByTypeDisplayValues.length > 1) {
				this.gridRows.push(gridTotalsRow);
			}
			this.createCollectionView(this.gridRows);
		}
	}

	private buildTagOverlapSubproperties(): void {
		const colorStyle: { color: string } = { color: '#A95C00' };
		Object.assign(this.groupData.totals.properties['Unique Total'], {
			subProperty: { 'Tag Delta': new GroupPropData('Tag Delta', { clickableValues: false }) },
			highlighted: true,
			subPropertyKey: 'Tag Delta',
		});
		this.groupData.totals.properties['Net'].hidden = true;
		this.groupData.totals.properties['Net'].subPropertyStyles = colorStyle;
	}

	private setTotalsValues(date: string, netValue: number, transactionAnalysis: AnalysisDataRoot<AnalysisTransactionValue>, index: number): void {
		if (this.groupData.totals.properties['Net']) {
			this.groupData.totals.properties['Net'].data[date] = this.formatter.formatValue(netValue, this.currencyConverted, this.trueRoundingOption);
		}
		this.groupData.totals.properties['Debit'].data[date] = this.formatter.formatValue(
			transactionAnalysis.summary[index]['debit'],
			this.currencyConverted,
			this.trueRoundingOption
		);
		this.groupData.totals.properties['Credit'].data[date] = this.formatter.formatValue(
			transactionAnalysis.summary[index]['credit'],
			this.currencyConverted,
			this.trueRoundingOption
		);
		this.updateTagOverlapTotals(transactionAnalysis, date, index, netValue);
	}

	private updateTagOverlapTotals(transactionAnalysis: AnalysisDataRoot<AnalysisTransactionValue>, date: string, index: number, netValue: number): void {
		if (!transactionAnalysis.tagOverlap) {
			return;
		}

		const uniqueSummary: AnalysisTransactionValue | AnalysisBalanceValue = transactionAnalysis.tagOverlap.uniqueTransactionsAnalysis.summary[index];
		const uniqueNetValue: number = uniqueSummary ? uniqueSummary['net'] : 0;
		const tagDelta: number = uniqueSummary ? netValue - uniqueNetValue : 0;

		this.groupData.totals.properties['Unique Total'].data[date] = this.formatCurrencyValue(uniqueNetValue);
		this.groupData.totals.properties['Unique Total'].subProperty['Tag Delta'].data[date] = this.formatCurrencyValue(tagDelta);
		if (this.groupData.totals.properties['Unique Total'].data[date] !== this.groupData.totals.properties['Net'].data[date]) {
			if (!this.groupData.totals.properties['Net'].dataMetadata) {
				this.groupData.totals.properties['Net'].dataMetadata = {};
			}
			this.groupData.totals.properties['Net'].dataMetadata[date] = {
				icon: 'warning',
				tooltip: 'Discrepancies detected',
			};
			this.groupData.totals.properties['Unique Total'].subProperty['Tag Delta'].propertyStylesByDate[date] = {
				[ScrollableDataCustomStyle.fontColor]: '#A95C00',
			};
		}
	}

	private formatCurrencyValue(value: number): string {
		return this.formatter.formatValue(value, this.currencyConverted, this.trueRoundingOption);
	}

	processTransactionGroup(aggregation: AnalysisDataAggregation<AnalysisTransactionValue>, groupData: GroupData, parentLabels: string[]): void {
		const currentAggregationLabels: string[] = [...parentLabels];
		const groupName: string = this.nameForId(aggregation.key, aggregation.value, AnalysisType.transactions);
		const groupValue: string = aggregation.value || this.ungroupedKey;
		const isSortable: boolean = aggregation.value !== AnalysisOtherGroupValue ? this.isSortable : false;
		groupData.groups[groupValue] = new Group(groupName);
		groupData.groups[groupValue].sortable = isSortable;
		groupData.groups[groupValue].groupId = groupValue;
		groupData.groups[groupValue].displayProperties = this.displayProperties;
		groupData.groups[groupValue].headerProperties = this.headerProperties;
		groupData.groups[groupValue].placeholderValue = this.placeholderValue;
		groupData.groups[groupValue].children = [];
		groupData.groups[groupValue].displayProperties.forEach((property: string) => {
			groupData.groups[groupValue].properties[property] = new GroupPropData(property, { clickableValues: true });
		});
		currentAggregationLabels.push(groupName);

		const creditGridRow: AnalysisGridRow = new AnalysisGridRow('Credit', currentAggregationLabels);
		creditGridRow.currency = this.currencyConverted.code;
		creditGridRow.currencyConverted = this.currencyConverted.code;

		const debitGridRow: AnalysisGridRow = new AnalysisGridRow('Debit', currentAggregationLabels);
		debitGridRow.currency = this.currencyConverted.code;
		debitGridRow.currencyConverted = this.currencyConverted.code;

		const netGridRow: AnalysisGridRow = new AnalysisGridRow('Net', currentAggregationLabels);
		netGridRow.currency = this.currencyConverted.code;
		netGridRow.currencyConverted = this.currencyConverted.code;

		[creditGridRow, debitGridRow, netGridRow].forEach((row: AnalysisGridRow) => {
			this.groupByTypeDisplayValues.forEach((groupBy, index) => {
				row[groupBy] = currentAggregationLabels[index] || (index === 0 ? 'Total' : currentAggregationLabels[index - 1] ? 'Total' : '');
			});
		});
		let transactions: AnalysisTransactionValue[];
		if (aggregation.summary) {
			transactions = aggregation.summary;
			groupData.groups[groupValue].childData = new GroupData();
			groupData.groups[groupValue].childData.sortable = this.isSortable;
			aggregation.aggregation.forEach(childGroup => {
				childGroup['sortable'] = this.isSortable;
				groupData.groups[groupValue].children.push(childGroup.value);
				this.processTransactionGroup(childGroup, groupData.groups[groupValue].childData, currentAggregationLabels);
			});
		} else if (aggregation.aggregation && aggregation.aggregation[0]?.summary) {
			transactions = aggregation.aggregation[0].summary;
		} else {
			return;
		}

		transactions.forEach((period, i) => {
			const formattedDate: string = DateTime.fromISO(period.date).toLocaleString(this.dateFormat);
			if (this.dateArray.length < transactions.length) {
				this.setDateLabel(period, formattedDate, i);
			}
			creditGridRow[formattedDate] = period.credit || 0;
			debitGridRow[formattedDate] = -period.debit || 0;
			groupData.groups[groupValue].properties['Credit'].data[formattedDate] = this.formatter.formatValue(
				period.credit || 0,
				this.currencyConverted,
				this.trueRoundingOption
			);
			groupData.groups[groupValue].properties['Debit'].data[formattedDate] = this.formatter.formatValue(
				-period.debit || 0,
				this.currencyConverted,
				this.trueRoundingOption
			);

			const netValue: number = (period.credit || 0) - (period.debit || 0);
			netGridRow[formattedDate] = netValue || 0;
			groupData.groups[groupValue].properties['Net'].data[formattedDate] = this.formatter.formatValue(
				netValue,
				this.currencyConverted,
				this.trueRoundingOption
			);
		});
		if (this.summaryColumn) {
			const creditMetricsVal: number = summaryOptionToMetricValue(AnalysisType.transactions, aggregation.metrics, 'credit', this.displaySettings.summaryColumn);
			const debitMetricsVal: number = summaryOptionToMetricValue(AnalysisType.transactions, aggregation.metrics, 'debit', this.displaySettings.summaryColumn);
			const netMetricsVal: number = summaryOptionToMetricValue(AnalysisType.transactions, aggregation.metrics, 'net', this.displaySettings.summaryColumn);
			groupData.groups[groupValue].properties['Credit'].data[this.summaryColumn.dataKey] = this.formatter.formatValue(
				creditMetricsVal || 0,
				this.currencyConverted,
				this.trueRoundingOption
			);
			groupData.groups[groupValue].properties['Debit'].data[this.summaryColumn.dataKey] = this.formatter.formatValue(
				-debitMetricsVal || 0,
				this.currencyConverted,
				this.trueRoundingOption
			);
			groupData.groups[groupValue].properties['Net'].data[this.summaryColumn.dataKey] = this.formatter.formatValue(
				netMetricsVal,
				this.currencyConverted,
				this.trueRoundingOption
			);
		}
		if (!this.netOnly) {
			this.gridRows.push(creditGridRow);
			this.gridRows.push(debitGridRow);
		} else {
			this.gridRows.push(netGridRow);
		}
	}

	generateBalanceGridRows(): void {
		if (this.analysisData.type === AnalysisType.balances) {
			let primaryDisplayBalance: string;
			let dualHeader: boolean = false;
			switch (this.analysisData.balanceProperty) {
				case AnalysisBalanceProperty.bankOpeningClosingLedgerConverted:
					primaryDisplayBalance = this.getBalanceTypeDisplayValue(AnalysisBalanceProperty.bankClosingLedgerConverted);
					this.primaryBalanceProperty = AnalysisBalanceProperty.bankClosingLedgerConverted;
					this.displayProperties = [primaryDisplayBalance];
					this.headerProperties.push(primaryDisplayBalance);
					dualHeader = true;
					break;
				case AnalysisBalanceProperty.bankOpeningClosingLedger:
					primaryDisplayBalance = this.getBalanceTypeDisplayValue(AnalysisBalanceProperty.bankClosingLedger);
					this.primaryBalanceProperty = AnalysisBalanceProperty.bankClosingLedger;
					this.displayProperties = [primaryDisplayBalance];
					this.headerProperties.push(primaryDisplayBalance);
					dualHeader = true;
					break;
				default:
					primaryDisplayBalance = this.getBalanceTypeDisplayValue(this.analysisData.balanceProperty);
					this.primaryBalanceProperty = this.analysisData.balanceProperty;
					this.displayProperties = [primaryDisplayBalance];
					this.headerProperties.push(primaryDisplayBalance);
			}
			this.secondaryBalanceProperty = this.balanceAnalysisOptions.getSecondaryBalanceType(this.primaryBalanceProperty);
			if (this.secondaryBalanceProperty) {
				const secondaryDisplayProperty: string = [
					...this.balanceAnalysisOptions.primaryBalanceOptions,
					...this.balanceAnalysisOptions.otherBalanceOptions,
				].find(option => option.value === this.secondaryBalanceProperty).view;
				if (dualHeader) {
					this.headerProperties.unshift(secondaryDisplayProperty);
					this.displayProperties.unshift(secondaryDisplayProperty);
				} else {
					this.displayProperties.unshift(secondaryDisplayProperty);
				}
			}
			this.groupData = new GroupData();
			this.groupData.sortable = this.isSortable;
			this.groupDisplayOrder = [];
			this.dateArray = [];
			this.dateLabels = [];
			this.gridRows = [];
			const balanceAnalysis: AnalysisDataRoot<AnalysisBalanceValue> = this.analysisData;

			if (balanceAnalysis.aggregation?.length) {
				balanceAnalysis.aggregation.forEach(group => {
					this.groupDisplayOrder.push(group.value);
					this.processBalanceGroup(group, this.groupData, [], dualHeader);
				});
			} else {
				this.groupDisplayOrder.push(null);
				this.processBalanceGroup(
					{ value: null, key: '', type: AnalysisType.balances, summary: balanceAnalysis.summary, aggregation: [], metrics: balanceAnalysis.metrics },
					this.groupData,
					[],
					dualHeader
				);
			}

			this.sortGridRows(this.groupDisplayOrder, this.gridRows);
			// set totals rows
			const gridTotalsRow: AnalysisGridRow = new AnalysisGridRow('Total', []);
			this.groupData.totals = new Group('Total');
			this.groupData.totals.displayProperties = this.displayProperties;
			this.groupData.totals.headerProperties = this.headerProperties;
			this.groupData.totals.placeholderValue = this.placeholderValue;
			this.dateArray.forEach((date, index) => {
				const analysisBalValue: AnalysisBalanceValue = balanceAnalysis.summary[index];
				gridTotalsRow[date] = analysisBalValue ? analysisBalValue[this.primaryBalanceProperty] : undefined;
				if (analysisBalValue) {
					gridTotalsRow.currency = analysisBalValue.currencyNative;
					gridTotalsRow.currencyConverted = analysisBalValue.currencyConverted;
				}
				gridTotalsRow.currency = (this.isConvertedCurrency ? null : this.currencyDict[balanceAnalysis.uniformCurrency]) || this.currencyConverted;
				this.displayProperties.forEach((property: string, propIndex: number) => {
					const currentBalanceProperty: AnalysisBalanceProperty =
						propIndex === 1 || this.displayProperties.length === 1 ? this.primaryBalanceProperty : this.secondaryBalanceProperty;
					if (!this.groupData.totals.properties[property]) {
						const cellMarker: string = this.secondaryBalanceProperty ? (propIndex === 1 ? 'C' : 'O') : null;
						this.groupData.totals.properties[property] = new GroupPropData(property, { cellMarker: cellMarker });
					}
					this.groupData.totals.properties[property].data[date] = this.formatter.formatValue(
						analysisBalValue ? analysisBalValue[currentBalanceProperty] : undefined,
						<Currency>gridTotalsRow.currency,
						this.trueRoundingOption
					);
				});

				if (!this.isConvertedCurrency) {
					gridTotalsRow[date] = formatNumber(gridTotalsRow[date] || 0, 'en-US', '1.2-2');
				}
			});
			if (this.summaryColumn) {
				this.displayProperties.forEach((property: string) => {
					const metricsVal: number = summaryOptionToMetricValue(AnalysisType.balances, balanceAnalysis.metrics, property, this.displaySettings.summaryColumn);
					this.groupData.totals.properties[property].data[this.summaryColumn.dataKey] = this.formatter.formatValue(
						metricsVal,
						<Currency>gridTotalsRow.currency,
						this.trueRoundingOption,
						false,
						false,
						false,
						true
					);
				});
			}
			this.createCopyHandler();
			this.setFrozenColumns();
			if (this.groupDisplayOrder.length > 1 || this.groupByTypeDisplayValues.length > 1) {
				this.gridRows.push(gridTotalsRow);
			}
			this.createCollectionView(this.gridRows);
		}
	}

	processBalanceGroup(
		aggregation: AnalysisDataAggregation<AnalysisBalanceValue>,
		groupData: GroupData,
		parentLabels: string[] = [],
		dualHeader: boolean = false
	): void {
		const currentAggregationLabels: string[] = [...parentLabels];
		const groupName: string = this.nameForId(aggregation.key, aggregation.value, AnalysisType.balances);
		const isSortable: boolean = aggregation.value !== AnalysisOtherGroupValue ? this.isSortable : false;
		groupData.groups[aggregation.value] = new Group(groupName);
		groupData.groups[aggregation.value].placeholderValue = this.placeholderValue;
		groupData.groups[aggregation.value].groupId = aggregation.value;
		groupData.groups[aggregation.value].sortable = isSortable;
		groupData.groups[aggregation.value].children = [];
		if (
			this.displayProperties?.length === 1 &&
			(this.displayProperties.indexOf('compositeFieldConverted') === 0 || this.displayProperties.indexOf('compositeField') === 0)
		) {
			groupData.groups[aggregation.value].displayProperties = [];
		} else {
			groupData.groups[aggregation.value].displayProperties = this.displayProperties;
		}
		groupData.groups[aggregation.value].headerProperties = this.headerProperties;
		currentAggregationLabels.push(groupName);

		let balances: AnalysisBalanceValue[] = [];
		if (aggregation.summary) {
			balances = aggregation.summary;
			groupData.groups[aggregation.value].childData = new GroupData();
			groupData.groups[aggregation.value].childData.sortable = this.isSortable;
			aggregation.aggregation.forEach(childGroup => {
				childGroup['sortable'] = this.isSortable;
				groupData.groups[aggregation.value].children.push(childGroup.value);
				this.processBalanceGroup(childGroup, groupData.groups[aggregation.value].childData, currentAggregationLabels, dualHeader);
			});
		} else if (aggregation.aggregation && aggregation.aggregation[0]?.summary) {
			balances = aggregation.aggregation[0].summary;
		}

		if (this.analysisData.balanceProperty.includes('composite') && balances.length) {
			const convertedFieldKey: string = this.analysisData.balanceProperty.includes('Converted') ? 'compositeFieldConverted' : 'compositeField';
			const compositePrimaryDisplayBalance: string = this.checkCompositeBalanceType(balances, convertedFieldKey);
			groupData.groups[aggregation.value].displayProperties = [compositePrimaryDisplayBalance];
			groupData.groups[aggregation.value].headerProperties = [compositePrimaryDisplayBalance];
		}

		const totalRows: AnalysisGridRow[] = [];
		groupData.groups[aggregation.value].displayProperties.forEach((property, propIndex) => {
			let totalRow: boolean = false;
			const isPrimaryProperty: boolean = propIndex === 1 || this.displayProperties.length === 1;
			const currentBalanceProperty: AnalysisBalanceProperty = isPrimaryProperty ? this.primaryBalanceProperty : this.secondaryBalanceProperty;
			const balanceGridRow: AnalysisGridRow = new AnalysisGridRow(property, currentAggregationLabels);

			this.groupByTypeDisplayValues.forEach((groupBy, gIndex) => {
				const label: string = currentAggregationLabels[gIndex] || (gIndex === 0 ? 'Total' : currentAggregationLabels[gIndex - 1] ? 'Total' : '');
				balanceGridRow[groupBy] = label;
				if (label === 'Total') {
					totalRow = true;
				}
			});
			const cellMarker: string = this.secondaryBalanceProperty ? (isPrimaryProperty ? 'C' : 'O') : null;
			groupData.groups[aggregation.value].properties[property] = new GroupPropData(property, { cellMarker: cellMarker });
			balanceGridRow.currency = (this.isConvertedCurrency ? null : this.currencyDict[aggregation.uniformCurrency]) || this.currencyConverted;
			balances.forEach((period, i) => {
				const formattedDate: string = DateTime.fromISO(period.date).toLocaleString(this.dateFormat);
				if (this.dateArray.length < balances.length) {
					this.setDateLabel(period, formattedDate, i);
				}
				balanceGridRow[formattedDate] = period[currentBalanceProperty];
				balanceGridRow.currency = this.displayProperties[0].includes('Converted') ? this.currencyConverted.code : period.currencyNative;
				balanceGridRow.currencyConverted = this.currencyConverted.code;
				if (this.displayProperties[0].includes('Converted')) {
					groupData.groups[aggregation.value].properties[property].data[formattedDate] = this.formatter.formatValue(
						period[currentBalanceProperty],
						this.currencyConverted,
						this.trueRoundingOption
					);
				} else {
					groupData.groups[aggregation.value].properties[property].data[formattedDate] = this.formatter.formatValue(
						period[currentBalanceProperty],
						period.currencyNative ? this.currencyDict[period.currencyNative] : null,
						this.trueRoundingOption,
						false,
						false,
						false,
						true
					);
				}
			});
			if (this.summaryColumn) {
				const metricsVal: number = summaryOptionToMetricValue(
					AnalysisType.balances,
					aggregation.metrics,
					currentBalanceProperty,
					this.displaySettings.summaryColumn
				);
				groupData.groups[aggregation.value].properties[property].data[this.summaryColumn.dataKey] = this.formatter.formatValue(
					metricsVal,
					this.currencyConverted,
					this.trueRoundingOption,
					false,
					false,
					false,
					true
				);
			}
			// this will be based on a user preference later but for now only primary property is displayed on the grid when not in dual header mode
			if (isPrimaryProperty || dualHeader) {
				if (!totalRow) {
					this.gridRows.push(balanceGridRow);
				} else {
					totalRows.push(balanceGridRow);
				}
			}
		});
		if (totalRows.length > 0) {
			this.gridRows = [...this.gridRows, ...totalRows];
		}
	}

	getBalanceTypeDisplayValue(balanceType: AnalysisBalanceProperty): string {
		const optionsList: BalanceAnalysisOption[] = [...this.balanceAnalysisOptions.primaryBalanceOptions, ...this.balanceAnalysisOptions.otherBalanceOptions];
		return optionsList.find(option => option.value === balanceType)?.view;
	}

	checkCompositeBalanceType(balances: AnalysisBalanceValue[], convertedFieldKey: string): string {
		let currentBalanceType: AnalysisBalanceProperty;

		balances.forEach((period: AnalysisBalanceValue) => {
			if (!currentBalanceType && period[convertedFieldKey]) {
				currentBalanceType = period[convertedFieldKey];
			} else if (period[convertedFieldKey] !== currentBalanceType || !period[convertedFieldKey]) {
				return 'Multiple';
			}
		});
		return this.getBalanceTypeDisplayValue(currentBalanceType);
	}

	private sortGridRows(groupIds: string[], gridRows: AnalysisGridRow[]): void {
		const groupInfoSorted: string[] = groupIds.map(id => this.groupData.groups[id].groupInfo);
		gridRows.sort((a, b) => {
			if (typeof a.groupValue === 'string' && typeof b.groupValue === 'string') {
				return groupInfoSorted.indexOf(a.groupValue) - groupInfoSorted.indexOf(b.groupValue);
			} else {
				return groupInfoSorted.indexOf(a.groupValue[0]) - groupInfoSorted.indexOf(b.groupValue[0]) || a.groupValue[1]?.localeCompare(b.groupValue[1]);
			}
		});
	}

	createCopyHandler(): void {
		this.wijmoCopyHandler = this.formatter.currencyWijmoCopyHandler(
			this.dateArray,
			this.currencyDict,
			this.placeholderValue,
			this.trueRoundingOption,
			!this.displaySettings.gridCurrencySymbols
		);
	}

	setFrozenColumns(): void {
		this.frozenGridColumns = this.groupByTypeDisplayValues.length + 1;

		if (this.showGridCurrencyColumn) {
			this.frozenGridColumns += 1;
		}
	}

	createCollectionView(gridRows: AnalysisGridRow[]): void {
		this.view = new CollectionView(gridRows);
		this.view.sortConverter = (sd, item, value) => {
			if (item['type'] === 'Total') {
				value = sd.ascending ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
			}
			return value;
		};
	}

	nameForId(key: string, id: string, type: AnalysisType): string {
		if (this.valuesDict) {
			const filter: GenericOption = this.valuesDict[mapGroupTypeToTQLField(key)]?.values?.find(value => value.id === id);
			if (filter) {
				return filter.displayValue;
			} else if (id === AnalysisOtherGroupValue) {
				return 'Other ' + (type === AnalysisType.balances ? 'Accounts' : 'Transactions');
			} else if (id === AnalysisTotalGroupValue || !id) {
				return 'All ' + (type === AnalysisType.balances ? 'Accounts' : 'Transactions');
			} else {
				return id;
			}
		}
		return;
	}

	getGroupTypeDisplay(groupBy: string): string {
		const groupBys: GenericOption[] = [...groupByOptions, ...accountGroupByOptions, ...transactionGroupByOptions];
		return groupBys.find((option: GenericOption) => option.id === groupBy).displayValue;
	}

	private setDateLabel(period: AnalysisBalanceValue | AnalysisTransactionValue, formattedDate: string, i: number): void {
		if (this.analysisData.cadence === Cadence.weekly) {
			if (this.analysisData.type === AnalysisType.balances) {
				// from requirements: the data and date that is displayed should correspond with the balance property that is selected:
				// Opening Ledger, Opening Ledger Converted, Opening Available, and Opening Available Converted should display Monday
				// Closing Ledger, and all other balance properties should Friday
				const days: number = this.analysisData.balanceProperty.toLowerCase().includes('opening') ? 4 : 0;
				this.dateLabels.push(DateTime.fromISO(period.date).minus({ days }).toLocaleString(this.dateFormat));
			} else {
				// account for transaction partial periods
				if (i === 0 && period.date !== this.analysisData.startDate) {
					this.dateLabels.push(DateTime.fromISO(this.analysisData.startDate).toLocaleString(this.dateFormat));
				} else {
					this.dateLabels.push(formattedDate);
				}
			}
		} else if (this.analysisData.cadence === Cadence.quarterly) {
			const date: DateTime = DateTime.fromISO(period.date);
			this.dateLabels.push('Q' + date.quarter + ' ' + date.toFormat('yyyy'));
		}
		this.dateArray.push(formattedDate);
	}

	private dateFormatter(cadence: Cadence): Intl.DateTimeFormatOptions {
		let dateFormat: Intl.DateTimeFormatOptions = {};
		switch (cadence) {
			case Cadence.daily:
			case Cadence.weekly: {
				dateFormat = { day: 'numeric', month: 'numeric', year: '2-digit' };
				break;
			}
			case Cadence.quarterly:
			case Cadence.monthly: {
				dateFormat = { month: 'short', year: 'numeric' };
				break;
			}
		}
		return dateFormat;
	}
}
