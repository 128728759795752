import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams, HttpErrorResponse } from '@angular/common/http';
import {
	GetTransactionsResponse,
	Transaction,
	TransactionsRequestBody,
	TransactionsSummary,
	TransactionsSummaryRequestBody,
	TransactionTagResponse,
} from '../models/transaction.model';
import { ParameterType, SearchParameter } from 'src/app/shared/models/search-parameter.model';
import { Tag } from '../models/tag.model';
import { catchError, retry, map, take } from 'rxjs/operators';
import { EMPTY, firstValueFrom, Observable, of, Subscriber, Subscription, throwError } from 'rxjs';
import { webSocket, WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/core/store/state/auth/auth.state';
import { firstValidValueFrom } from 'src/app/shared/utils/firstValidValueFrom';
import { InstitutionFacadeService } from 'src/app/shared/services/facade/institution.facade.service';
import { TqlService } from '@trovata/app/shared/services/tql.service';
import { GLTag } from '../models/glTag.model';
import { GLCode } from '../models/gl-code.model';
import { TagOverlapAnalysis } from '../../reports/models/analysis.model';
import { DateTime } from 'luxon';
import { TagOverlapTransactionMode } from '../../reports/components/analysis-data-table/analysis-data-table.component';

export interface TagOverlapSummaryParameters {
	startDate: string;
	endDate: string;
	tql?: object;
}

@Injectable({
	providedIn: 'root',
})
export class TransactionsService {
	private webSocket: WebSocketSubject<string[] | string>;
	private suggestions$: Observable<string[]>;

	constructor(
		private httpClient: HttpClient,
		private store: Store,
		private insitutionFacadeService: InstitutionFacadeService,
		private tqlService: TqlService
	) {
		this.suggestions$ = of([]);
		this.createSuggestionsObservable();
	}

	getTransactions(
		tags: Tag[],
		glTags: GLTag[],
		glCodes: GLCode[],
		q?: string,
		positionType?: string,
		from?: number,
		params?: SearchParameter[],
		size?: number,
		fullAccountNumbers: boolean = false,
		currencyOverride?: string,
		tqlJSONExpression?: object,
		transactionIds?: string[],
		includeCurrencySummary: boolean = true,
		tagOverlapMode?: TagOverlapTransactionMode
	): Observable<HttpResponse<GetTransactionsResponse>> {
		const body: TransactionsRequestBody = {};
		body.q = q?.trim();
		if (positionType && positionType !== 'MANUAL') {
			body.positionType = [positionType];
		}
		if (from) {
			body.from = from;
		}
		if (size) {
			body.size = size;
		}
		if (fullAccountNumbers) {
			body.fullAccountNumbers = fullAccountNumbers;
		}
		if (currencyOverride) {
			body.currencyOverride = currencyOverride;
		}
		if (positionType === 'MANUAL') {
			body.institutionType = 'MANUAL';
		}
		body.tagOverlapOnly = tagOverlapMode === TagOverlapTransactionMode.tagOverlapOnly;

		if (transactionIds && transactionIds.length) {
			body.includeTransactions = transactionIds;
		}
		body.includeCurrencySummary = includeCurrencySummary;

		body.tagVerbosity = 'id';

		const tagIds: string[] = [];
		params?.forEach((param: SearchParameter) => {
			if (param.type === ParameterType.isManual) {
				body.institutionType = 'MANUAL';
			} else if (
				param.type === ParameterType.START_DATE ||
				param.type === ParameterType.END_DATE ||
				param.type === ParameterType.untagged ||
				param.type === ParameterType.noGlTag ||
				param.type === ParameterType.startIngestionTimestamp ||
				param.type === ParameterType.endIngestionTimestamp
			) {
				body[param.type] = param.value;
			} else if (param.type === ParameterType.tag || param.type === ParameterType.tagId) {
				tagIds.push(param.value);
			} else {
				if (!body[param.type]) {
					body[param.type] = [];
				}

				body[param.type].push(param.value);
			}
		});
		if (tqlJSONExpression || tagIds.length > 0) {
			const expression: object = tagIds.length > 0 ? this.tqlService.addParameterToTQLExpression(tqlJSONExpression, 'tag', tagIds) : tqlJSONExpression;
			body.tql = { type: 'AST', expression };
		}
		const url: string = environment.trovataAPI('workspace') + '/data/transactions';
		return new Observable<HttpResponse<GetTransactionsResponse>>((observer: Subscriber<HttpResponse<GetTransactionsResponse>>) => {
			this.httpClient
				.post<GetTransactionsResponse>(url, body, {
					observe: 'response',
				})
				.pipe(
					map(async (res: HttpResponse<GetTransactionsResponse>) => {
						const institutionDict: Map<string, string> = await this.insitutionFacadeService.getInstitutionDict();
						let conflictingDateTags: string[] = [];
						if (tagOverlapMode && tagOverlapMode !== TagOverlapTransactionMode.none && res.body['transactions'].length > 0) {
							conflictingDateTags = await this.getConflictingDateTags(res.body['transactions'], tqlJSONExpression);
						}
						res.body['transactions'].forEach((trxn: Transaction) => {
							if (conflictingDateTags?.length && trxn.tags?.length > 1) {
								this.setOverlappedTags(trxn, conflictingDateTags);
							}
							if (trxn.account.institutionNickname === undefined || !trxn.account.institutionNickname) {
								trxn.account.institutionNickname = institutionDict[trxn.account.institutionId];
							}
							if (trxn.tags) {
								const trxnTagIds: string[] = trxn.tags.map((tag: Tag) => tag.tagId);
								trxn.tags = (tags || []).filter((tag: Tag) => trxnTagIds.includes(tag.tagId));
							}
							if (trxn.glTag) {
								const glTagId: string = trxn.glTag.toString();
								const fullGlTag: GLTag | undefined = glTags?.find((glTag: GLTag) => glTag.glTagId === glTagId);
								const glCode1: GLCode = glCodes?.find((glCode: GLCode) => glCode.codeId === fullGlTag?.glCode1);
								const glCode2: GLCode = glCodes?.find((glCode: GLCode) => glCode.codeId === fullGlTag?.glCode2);
								trxn.glTag = fullGlTag;
								trxn.glCode1 = glCode1;
								trxn.glCode2 = glCode2;
							}
						});
						observer.next(res);
						observer.complete();
						return res;
					}),
					catchError((err: HttpErrorResponse) => {
						const sizeParam: number = body.size;
						if (err.status === 413 && body.size) {
							body.size = Math.floor(sizeParam / 2);
						}
						observer.error(err);
						observer.complete();
						return throwError(() => err);
					}),
					retry(3)
				)
				.subscribe();
		});
	}

	private async getConflictingDateTags(transactions: Transaction[], tql: object): Promise<string[]> {
		const isoTransactionDates: string[] = transactions.map((trxn: Transaction) => this.normalizeDate(trxn.date));
		isoTransactionDates.sort();

		const overlapResponse: TagOverlapAnalysis = await firstValueFrom(
			this.getTagOverlapSummary({
				startDate: isoTransactionDates.at(0),
				endDate: isoTransactionDates.at(-1),
				tql: tql
					? {
							type: 'AST',
							expression: tql,
						}
					: null,
			})
		);

		return overlapResponse.affectedTags;
	}

	private normalizeDate(date: string | Date): string {
		if (typeof date === 'string') {
			return DateTime.fromISO(date, { zone: 'UTC' }).startOf('day').toFormat('yyyy-MM-dd');
		}
		return DateTime.fromJSDate(date).toUTC().startOf('day').toFormat('yyyy-MM-dd');
	}

	private setOverlappedTags(trxn: Transaction, conflictingDateTags: string[]): void {
		if (trxn.tags?.length <= 1 || conflictingDateTags?.length === 0) {
			// If there is only one tag, there can't be any overlap
			return;
		}
		trxn.overlappedTags = trxn.tags?.filter((tag: Tag) => conflictingDateTags.includes(tag.tagId)).map((tag: Tag) => tag.tagId);
	}

	private getTagOverlapSummary(params: TagOverlapSummaryParameters): Observable<TagOverlapAnalysis> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/transactions/tag-overlap/summary`;

		const body: TagOverlapSummaryParameters = {
			startDate: params.startDate,
			endDate: params.endDate,
		};

		if (params.tql) {
			body.tql = params.tql;
		}

		return this.httpClient.post<TagOverlapAnalysis>(url, body);
	}

	getTransactionsSummary(
		q?: string,
		positionType?: string,
		from?: number,
		params?: SearchParameter[],
		size?: number,
		fullAccountNumbers: boolean = false,
		currencyOverride?: string,
		ast?: object,
		includeCurrencySummary: boolean = true,
		tagOverlapMode?: TagOverlapTransactionMode
	): Observable<HttpResponse<TransactionsSummary>> {
		const body: TransactionsSummaryRequestBody = {};
		body.q = q;
		if (from) {
			body.from = from;
		}
		if (size) {
			body.size = size;
		}
		if (fullAccountNumbers) {
			body.fullAccountNumbers = fullAccountNumbers;
		}
		if (currencyOverride) {
			body.currencyOverride = currencyOverride;
		}
		body.tagOverlapOnly = tagOverlapMode === TagOverlapTransactionMode.tagOverlapOnly;

		body.includeCurrencySummary = includeCurrencySummary;

		const tagIds: string[] = [];
		params?.forEach((param: SearchParameter) => {
			if (param.type === ParameterType.isManual) {
				body.institutionType = 'MANUAL';
			} else if (
				param.type === ParameterType.START_DATE ||
				param.type === ParameterType.END_DATE ||
				param.type === ParameterType.untagged ||
				param.type === ParameterType.noGlTag ||
				param.type === ParameterType.startIngestionTimestamp ||
				param.type === ParameterType.endIngestionTimestamp
			) {
				body[param.type] = param.value;
			} else if (param.type === ParameterType.tag || param.type === ParameterType.tagId) {
				tagIds.push(param.value);
			} else if (param.type === ParameterType.START_DATE || param.type === ParameterType.END_DATE) {
				body[param.type] = this.normalizeDate(param.value);
			} else {
				if (!body[param.type]) {
					body[param.type] = [];
				}

				body[param.type].push(param.value);
			}
		});

		if (positionType === 'MANUAL') {
			body.institutionType = 'MANUAL';
		}

		if (ast || tagIds.length > 0) {
			const expression: object = tagIds.length > 0 ? this.tqlService.addParameterToTQLExpression(ast, 'tag', tagIds) : ast;
			body.tql = { type: 'AST', expression };
		}
		const url: string = environment.trovataAPI('workspace') + '/data/transactions/summary';

		return this.httpClient.post<TransactionsSummary>(url, body, {
			observe: 'response',
		});
	}

	getTransactionTags(
		filters: Map<ParameterType, string[]>,
		startDate: string,
		endDate?: string,
		positionType?: string,
		isIntraday?: boolean,
		tagVerbosity: string = 'id',
		isBatch?: boolean,
		isManual: boolean = false,
		from?: number,
		size?: number
	): Observable<HttpResponse<TransactionTagResponse>> {
		const parameters: HttpParams = new HttpParams();
		const body: object = {};
		body['startDate'] = startDate;
		if (endDate) {
			body['endDate'] = endDate;
		}
		if (from) {
			body['from'] = from;
		}
		if (size) {
			body['size'] = size;
		}
		if (isBatch) {
			body['isBatch'] = isBatch;
		}
		if (isIntraday) {
			body['isIntraday'] = true;
		}
		if (tagVerbosity) {
			body['tagVerbosity'] = tagVerbosity;
		}

		[
			ParameterType.accountId,
			ParameterType.institutionId,
			ParameterType.q,
			ParameterType.accountId,
			ParameterType.entity,
			ParameterType.region,
			ParameterType.division,
			ParameterType.currency,
			ParameterType.transactionId,
		].forEach((type: ParameterType) => {
			body[type] = filters.get(type);
		});
		if (positionType === 'MANUAL' || isManual) {
			body['institutionType'] = 'MANUAL';
		} else {
			if (positionType) {
				body['positionType'] = [positionType];
			}
		}
		const url: string = `${environment.trovataAPI('workspace')}/data/transactions/tags`;
		return this.httpClient
			.post<TransactionTagResponse>(url, body, {
				params: parameters,
				observe: 'response',
			})
			.pipe(
				catchError((err: HttpErrorResponse) => {
					throwError(err);
					return EMPTY;
				})
			);
	}
	/**
	 * Pushes a message to the server, if connection is closed, messages are queued until the connection is established.
	 * @param query Message to be sent
	 */
	sendSuggestionQuery(query?: string): Observable<never> {
		this.webSocket.next(query ? query : '@suggest');
		return EMPTY;
	}
	/**
	 * Opens a websocket connection on subscription.
	 * The connection is closed automatically when all consumers unsubscribe.
	 */
	getTransactionsSuggestions(limit: number = 5): Observable<string[]> {
		return this.suggestions$.pipe(map((suggestions: string[]) => suggestions.slice(0, limit)));
	}

	private createWebsocket(authToken: string): WebSocketSubject<string> {
		const endpoint: URL = new URL(environment.searchSocket);
		endpoint.searchParams.set('authorizationToken', authToken);
		const socketConfig: WebSocketSubjectConfig<string> = {
			url: endpoint.toString(),
			serializer: (value: string) => value,
		};
		return webSocket(socketConfig);
	}

	private getAccessToken(): Promise<string> {
		return firstValidValueFrom(this.store.select(AuthState.accessToken));
	}

	private async createSuggestionsObservable(): Promise<void> {
		let firstSuggestResults: string[] = [];
		const accessToken: string = await this.getAccessToken();
		this.webSocket = this.createWebsocket(accessToken);
		this.webSocket
			.asObservable()
			.pipe(take(1))
			.subscribe((firstResults: string[]) => (firstSuggestResults = firstResults));

		this.suggestions$ = new Observable<string[]>((subscriber: Subscriber<string[]>) => {
			subscriber.next(firstSuggestResults);
			const socketSubscription: Subscription = this.webSocket.asObservable().subscribe({
				next: (serverResults: string[]) => subscriber.next(serverResults),
				error: subscriber.error,
				complete: subscriber.complete,
			});
			return () => {
				socketSubscription.unsubscribe();
			};
		});
	}
}
